import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";
import { blogList } from "../../data/blogs";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/first-time-home-buyer-what-to-know");

  return (
    <LayoutWrapper
      title="First Time Home Buyer? What You Need to Know | The Selby Team"
      desc="Buying a house for the first time is a big accomplishment. But the process can feel overwhelming. That’s where our list of first-time home buyer tips can help."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              First Time Home Buyer? What You Need to Know
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">May 20, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_First Time Home Buyer_ What You Need to Know.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Buying a house is a major accomplishment. But if it’s your first time, the process
                can feel a bit unfamiliar, if not overwhelming. So here are some first-time home
                buyer tips to get you started.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Prequalification vs. Preapproval
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Mortgage lenders commonly offer “prequalification,” which gives you a better idea of
                your purchase budget. This can be one of the most important first-time home buyer
                tips since it will help you search for homes within your price range.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Preapproval is a more in-depth examination of your financial situation. You can save
                this step for when you’re ready to make an offer on a home. A preapproval letter can
                lend credibility to your offer and streamline home buying.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                You Don’t Always Need a 20% Down Payment.
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Many first-time home buyers assume they’ll need a 20% down payment to purchase a
                home. While this is customary, it’s not always necessary. Conventional loans are
                available for as little as 3% down if you have good credit.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                However, home buyers should know that if you don’t put down 20%, your loan program
                may require you to pay for “private mortgage insurance,” or PMI. These payments can
                be rolled into your mortgage premiums, increasing monthly costs.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Explore Your Mortgage Options
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Not all home loan programs are the same. While conventional mortgages typically
                offer some of the best rates and terms, other programs can make it easier for
                first-time home buyers. Consider loan options such as:
              </p>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li className="mb-2">
                  <strong>FHA Loans: </strong>Available for those with low credit scores
                </li>
                <li className="py-2">
                  <strong>USDA Loans: </strong> No down payment for qualifying homes in rural areas
                </li>
                <li className="py-2">
                  <strong>VA Loans: </strong> No down payment for current/former members of the
                  military
                </li>
              </ul>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                For instance, FHA loans require only a 10% down payment, even if your credit score
                is 500. But if your credit score is 580 or above, you can secure an FHA loan with
                only 3.5% down.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Learn more about{" "}
                <a
                  href="https://selbysellssd.com/different-types-of-home-loans/"
                  target="_Blank"
                  className="text-blue-500"
                >
                  the different types of home loans
                </a>{" "}
                to determine which option is right for you.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Save Some Money for Repairs
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                One of the best first-time home buyer tips is to keep your bank account active when
                you purchase a home. You'll need enough to cover closing costs — usually 2% to 6% of
                the home's purchase price.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                But you may also need funding for home repairs, new appliances, lawn equipment, and
                more. So cleaning out your savings account to make a larger down payment can make it
                harder to make future home improvements.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Always Compare Lenders
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Aim to compare at least three different mortgage lenders to find the best rates and
                terms. Don't forget to look at any "hidden" fees such as origination or other
                administrative costs.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                You can even apply for preapproval letters from multiple lenders simultaneously.
                Doing so will protect your credit if you submit your applications within the same
                30-day period.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Bonus Tip: Get Help from an Experienced Realtor
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                These first-time home buyer tips can set you in the right direction. But it also
                helps to have{" "}
                <ContactSlideoutLink text="a team of experienced real estate professionals" /> on
                your side. When you’re ready to look for your first home,{" "}
                <a href="tel: (619) 259-6482" target="_Blank" className="text-blue-500">
                  contact the Selby Team
                </a>{" "}
                to explore your options.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
